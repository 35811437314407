import React from "react";
import ExperienceCard from "./ExperienceCard";
import "../../index.css";

const experienceData = [
  {
    badge: "May 2022 - Nov 2024",
    title: "Full Stack Developer",
    subTitle: "Webmount Studio, Toronto",
    des: [
      [
        { text: "Developed and deployed scalable applications on ", bold: false },
        { text: "AWS", bold: true },
        { text: ", managing cloud infrastructure across various providers including ", bold: false },
        { text: "Azure, GCP, IBM Cloud, and Oracle Cloud", bold: true },
        { text: " to optimize performance and reduce costs.", bold: false }
      ],
      [
        { text: "Implemented CI/CD pipelines using ", bold: false },
        { text: "Jenkins and GitLab", bold: true },
        { text: " for automated deployment processes.", bold: false }
      ],
      [
        { text: "Designed and managed cloud architectures on ", bold: false },
        { text: "AWS", bold: true },
        { text: " to optimize cost and performance.", bold: false }
      ],
      [
        { text: "Used ", bold: false },
        { text: "Docker and Kubernetes", bold: true },
        { text: " for efficient container orchestration.", bold: false }
      ],
      [
        { text: "Monitored and optimized application performance using ", bold: false },
        { text: "Prometheus and Grafana.", bold: true }
      ],
      [
        { text: "Developed and deployed cloud solutions on ", bold: false },
        { text: "Microsoft Azure", bold: true },
        { text: " including Azure Functions and Azure DevOps.", bold: false }
      ],
      [
        { text: "Integrated data processing and analytics services using ", bold: false },
        { text: "GCP", bold: true },
        { text: " such as BigQuery and Cloud Storage.", bold: false }
      ],
      [
        { text: "Applied DevOps practices to automate workflows and manage application lifecycles using ", bold: false },
        { text: "Terraform", bold: true },
        { text: " for infrastructure as code.", bold: false }
      ],
      [
        { text: "Built and maintained APIs with ", bold: false },
        { text: "Node.js and Express.js", bold: true },
        { text: " for backend services and integrations.", bold: false }
      ],
      [
        { text: "Enhanced user interfaces and performance using modern JavaScript frameworks like ", bold: false },
        { text: "React.js and Angular", bold: true }
      ],
      [
        { text: "Conducted code reviews and implemented best practices for high code quality and maintainability.", bold: false }
      ]
    ],
  }
];

const Experience = () => {
  return (
    <div
      className="p-6 bg-gray-100 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 space-y-4"
    >
      {experienceData.map((item, index) => (
        <ExperienceCard
          key={index}
          title={item.title}
          subTitle={`${item.subTitle}, ${item.badge}`}
          des={item.des}
        />
      ))}
    </div>
  );
};

export default Experience;
